<template>
  <div class="mt-5">
    <v-row no-gutters>
      <v-col cols="12" md="4" sm="4" lg="4" xl="4">
        <v-toolbar dense elevation="0">
          <div class="mr-5"><h3>Credits</h3></div>
          <span
            class="ml-n2 mt-1 font-weight-bold"
            style="font-size: 15px; color: #424242"
          >
            ( Customers & Prospects )
          </span>
        </v-toolbar>
      </v-col>
      <v-spacer />
      <v-col cols="12" md="3" sm="3" lg="3" xl="3">
        <v-select
          v-model="creditStatus"
          :items="creditStatusItems"
          dense
          outlined
          item-text="text"
          item-value="value"
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="3" sm="3" lg="3" xl="3">
        <v-toolbar dense class="elevation-0">
          <v-text-field
            v-model="search"
            label="search"
            class="mr-2 mt-5"
            dense
            append-icon="mdi-magnify"
          ></v-text-field>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row no-gutters class="mx-4">
      <v-col cols="12" md="12" sm="12" lg="12" xl="12">
        <v-data-table
          :headers="creditsData"
          :items="creditsItems"
          dense
          class="overflow-y-auto overflow"
          :fixed-header="fixed"
          :height="height"
          :search="search"
          :loading="isLoading"
          :items-per-page="10"
        >
          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text">
              No Organisations available
            </v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
          
          <template v-slot:[`item.consumed_credits`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  class="gradient-bg white--text"
                  x-small
                  width="50"
                  @click="viewConsumedCredits(item)"
                  >{{item.consumed_credits
                    
                  }}</v-btn
                >
              </template>
              <span class="white--text">View Credits Consumed </span>
            </v-tooltip>
          </template>

          <!-- <template v-slot:[`item.totalconsumed`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  class="gradient-bg white--text"
                  x-small
                  width="50"
                  @click="viewConsumedCredits(item)"
                  >{{
                    Number(item.organisation_total_camera_swipes) != undefined
                      ? Number(item.organisation_total_camera_swipes)
                      : 0 + Number(item.organisation_total_datacorrections) !=
                        undefined
                      ? Number(item.organisation_total_datacorrections)
                      : 0 + Number(item.organisation_total_proxy_swipes) !=
                        undefined
                      ? Number(item.organisation_total_proxy_swipes)
                      : 0 + Number(item.organisation_total_reswipes) !=
                        undefined
                      ? Number(item.organisation_total_reswipes)
                      : 0 + Number(item.organisation_total_whatsapp_swipes) !=
                        undefined
                      ? Number(item.organisation_total_whatsapp_swipes)
                      : 0
                  }}</v-btn
                >
              </template>
              <span class="white--text">View Credits Consumed </span>
            </v-tooltip>
          </template> -->






          <template v-slot:[`item.add`]="{ item }">
            <v-btn
              @click="addCredits(item)"
              class="gradient-bg white--text"
              x-small
              v-if="item"
              >Add Credits</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <Overlay :overlay="overlay" />
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <AddCredits
      @getCredits="call_get_credit"
      :creditsdialog="creditsdialog"
      :CreditsObject="CreditsObject"
      @closedialog="creditsdialog = false"
    />
    <ViewConsumedCredits
      :creditsdialog="viewconsumedDialog"
      :CreditsObject="veiwCreditsObj"
      @closedialog="viewconsumedDialog = false"
    />
  </div>
</template>

<script>
import Overlay from "@/components/Overlay.vue";
import SnackBar from "@/components/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { ListOrganisationCredits } from "@/graphql/queries.js";
import AddCredits from "@/components/Dialogs/Credits/AddCredits.vue";
import ViewConsumedCredits from "@/components/Dialogs/Credits/ViewConsumedCredits.vue";

export default {
  components: {
    Overlay,
    AddCredits,
    SnackBar,
    ViewConsumedCredits,
  },
  data: () => ({
    isLoading: false,
    creditsData: [
      { text: "Organization Name", value: "organisation_name" },
      { text: "Email ID", value: "user_email_id" },
      { text: "Available Credits", value: "organisation_total_credits" },
      { text: "Consumed Credits", value: "consumed_credits" },
      { text: "Actions", value: "add" },
    ],
    creditsItems: [],
    search: "",
    fixed: true,
    height: 0,
    overlay: false,
    SnackBarComponent: {},
    creditStatus: "lowestCreditsAvaliable",
    creditStatusItems: [
      { text: "Lowest Available Credit", value: "lowestCreditsAvaliable" },
      { text: "Highest Available Credit", value: "highestCreditsAvaliable" },
      { text: "Lowest Consumed Credit", value: "lowestComsumedCredits" },
      { text: "Highest Consumed Credit", value: "highestComsumedCredits" },
    ],
    CreditsObject: {},
    creditsdialog: false,
    viewconsumedDialog: false,
    veiwCreditsObj: {},
  }),
  mounted() {
    this.height = window.innerHeight - 190;
    this.creditStatus = "lowestCreditsAvaliable";
    this.get_credits_list(this.creditStatus);
  },
  watch: {
    creditStatus(val) {
      this.get_credits_list(val);
    },
  },
  methods: {
    addCredits(item) {
      this.CreditsObject = {};
      this.CreditsObject = item;
      this.creditsdialog = true;
    },
    viewConsumedCredits(item) {
      this.veiwCreditsObj = {};
      this.veiwCreditsObj = item;
      this.viewconsumedDialog = true;
    },
    call_get_credit() {
      this.get_credits_list(this.creditStatus);
    },
    async get_credits_list(credit) {
      this.creditsItems = [];
      this.isLoading = true;
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(ListOrganisationCredits, {
            input: {
              credits_type: credit,
            },
          })
        );
        this.creditsItems =
          JSON.parse(result.data.ListOrganisationCredits) != null
            ? JSON.parse(result.data.ListOrganisationCredits)
            : [];
            // console.log(  this.creditsItems);
            
        this.isLoading = false;
        this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.isLoading = false;
        this.overlay = false;
      }
    },
  },
};
</script>

<style></style>
