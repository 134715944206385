<template>
  <div>
    <v-dialog v-model="creditsdialog" width="890px" persistent>
      <v-card class="overflow-hidden">
        <v-toolbar class="gradient-bg" dense>
          <v-toolbar-title class="white--text"
            >Credits Consumed - {{ Org_Name }}</v-toolbar-title
          >
          <v-spacer />
          <v-btn class="white--text" @click="closeDialog()" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row class="ma-3">
          <v-col cols="12" md="6">
            <v-row class="" no-gutters>
              <v-col cols="12" md="6">
                <h3 style="font-size: 20px" class="font-weight-medium">
                  Scan Source
                </h3>
              </v-col>
              <v-col cols="12" md="6">
                <h3 style="font-size: 20px" class="font-weight-medium">
                  Credits Consumed
                </h3>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="1">
                <v-icon color="#65C18C" small> mdi-camera </v-icon> <br />
                <v-icon color="#219F94" small> mdi-redo-variant </v-icon> <br />
                <v-icon color="#00B4D8" small> mdi-whatsapp </v-icon><br />
                <v-icon color="#FF8000" small> mdi-human-edit </v-icon><br />
                <v-icon color="#FF0000" small> mdi-odnoklassniki </v-icon>
              </v-col>
              <v-col cols="12" md="5">
                <span style="font-size: 16px"> Camera </span> <br />
                <span style="font-size: 16px"> ReSwipe </span> <br />
                <span style="font-size: 16px"> WhatsApp </span> <br />
                <span style="font-size: 16px"> Data Correction </span> <br />
                <span style="font-size: 16px"> Proxy </span>
              </v-col>
              <v-col class="pa-0 ma-0" cols="12" md="6">
                <span class="ml-1" style="font-size: 16px">
                  {{ CreditsObject.organisation_total_camera_swipes }}
                </span>
                <br />
                <span class="ml-1" style="font-size: 16px">
                  {{ CreditsObject.organisation_total_reswipes }}
                </span>
                <br />
                <span class="ml-1" style="font-size: 16px">
                  {{ CreditsObject.organisation_total_whatsapp_swipes }}
                </span>
                <br />
                <span class="ml-1" style="font-size: 16px">
                  {{ CreditsObject.organisation_total_datacorrections }}
                </span>
                <br />
                <span class="ml-1" style="font-size: 16px">
                  {{
                    CreditsObject.organisation_total_proxy_swipes
                      ? CreditsObject.organisation_total_proxy_swipes
                      : "N/A"
                  }}
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="count == 1" cols="12" md="6">
            <PolarChart
              :data="chartData"
              :options="chartOptions"
              :count="count"
            ></PolarChart>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PolarChart from "@/components/Charts/PolarChart.js";

export default {
  props: {
    creditsdialog: Boolean,
    CreditsObject: Object,
  },
  components: {
    PolarChart,
  },
  data: () => ({
    Org_Name: "",
    showChart: false,
    chartOptions: {
      hoverBorderWidth: 20,
      responsive: true,
      maintainAspectRatio: false,
    },
    chartData: {
      hoverBackgroundColor: "red",
      hoverBorderWidth: 10,
      labels: ["Camera", "ReSwipe", "WhatsApp", "Data Correction", "Proxy"],
      datasets: [
        {
          label: "Consumed Credits",
          backgroundColor: [
            "#65C18C",
            "#219F94",
            "#00B4D8",
            "#FF8000",
            "#FF0000",
          ],
          data: [],
        },
      ],
    },
    count: 0,
  }),
  watch: {
    async creditsdialog(val) {
      if (val == true) {
        this.count = 0;
        this.Org_Name = this.CreditsObject.organisation_name;
        await this.viewChart(this.CreditsObject);
        this.count = 1;
      }
    },
  },
  methods: {
    viewChart(CreditsObject) {
      let consumedSwipes = [];
      consumedSwipes.push(CreditsObject.organisation_total_camera_swipes);
      consumedSwipes.push(CreditsObject.organisation_total_reswipes);
      consumedSwipes.push(CreditsObject.organisation_total_whatsapp_swipes);
      consumedSwipes.push(CreditsObject.organisation_total_datacorrections);
      consumedSwipes.push(CreditsObject.organisation_total_proxy_swipes);
      this.chartData.datasets[0].data = consumedSwipes;
      this.$forceUpdate();
    },
    closeDialog() {
      this.$emit("closedialog");
    },
  },
};
</script>

<style></style>
